import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import speciesBank from './modules/speciesBank';
import system from './modules/system';
import userInfo from './modules/userInfo';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    speciesBank, // 物种库
    system, // 系统信息
    userInfo, // 用户登录信息
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })]
})
