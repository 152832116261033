import { createVNode, render, isVNode } from 'vue'
import AlertConstructor from './index'

const instances = []
let seed = 1
const Alert = function(opts) {
  if (typeof opts === 'string') {
    opts = {
      content: opts,
    }
  }
  let verticalOffset = opts.offset || 20
  instances.forEach(({ vm }) => {
    verticalOffset += (vm.el.offsetHeight || 0) + 16
  })
  verticalOffset += 16

  const id = 'sx_alert_' + seed++
  const options = {
    ...opts,
    offset: verticalOffset,
    id,
    onClose: () => {
      close(id)
    },
  }

  const container = document.createElement('div')
  container.className = `container_${id}`
  const vm = createVNode(AlertConstructor, options, isVNode(options.content) ? { default: () => options.content } : null)
  vm.props.onDestroy = () => {
    render(null, container)

  }
  render(vm, container)
  instances.push({ vm })
  if (document.getElementById('displayArea')) {
    document.getElementById('displayArea').appendChild(container.firstElementChild)
  } else {
    document.body.appendChild(container.firstElementChild)
  }
  return {
    close: () => vm.component.proxy.visble = false
  }
}

export const close = (id) => {
  const idx = instances.findIndex(({vm}) => vm.component.props.id === id)
  if (idx === -1) return
  const {vm} = instances[idx]
  if (!vm) return
  instances.splice(idx, 1)
  for (let i = idx; i < instances.length; i++) {
    instances[i].vm.component.props.offset = parseInt(instances[i].vm.el.style['top'], 10) - vm.el.offsetHeight - 16
  }
}

export const closeAll = () => {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].vm.component.ctx.close()
  }
}

Alert.closeAll = closeAll
export default Alert
