<template>
  <transition enter-active-class="animated centerFadeInDown" leave-active-class="animated centerFadeOutUp" :duration="300" @before-leave="onClose"
              @after-leave="$emit('destroy')">
    <div class="sx-popup-alert" v-show="visible" :id="id" role="alert" :style="customStyle"
         @mouseenter="clearTimer"
         @mouseleave="startTimer"
    >
      <slot><p>{{ content }}</p></slot>
    </div>
  </transition>
</template>

<script>
import { ref, computed, onMounted } from 'vue'

export default {
  name: 'sx-popup-alert',
  props: {
    duration: { type: Number, default: 3000 },
    content: { type: [String, Object], default: '' },
    id: { type: String, default: '' },
    offset: { type: Number, default: 20 },
    onClose: { type: Function, required: true },
  },
  emits: ['destroy'],
  setup(props) {
    const customStyle = computed(() => {
      return {
        top: `${props.offset}px`,
      }
    })
    const visible = ref(false)
    let timer = null

    function startTimer() {
      if (props.duration > 0) {
        timer = setTimeout(() => {
          if (visible.value) {
            close()
          }
        }, props.duration)
      }
    }

    function clearTimer() {
      clearTimeout(timer)
      timer = null
    }

    function close() {
      visible.value = false
    }

    onMounted(() => {
      startTimer()
      visible.value = true
    })

    return {
      visible,
      close,
      clearTimer,
      startTimer,
      customStyle,
    }
  },
}
</script>

<style lang="scss" scoped>

.sx-popup-alert {
  position: absolute;
  top: 150px;
  left: 50%;
  z-index: 999;
  transform: translate(-50%);
  width: 411px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  background: url("~_a/login/tip.png") no-repeat 0 0;
  color: rgba(218, 206, 49, 1);
  font-size: 18px;
}
</style>
