import { createVNode, render } from 'vue'
import AlertConstructor from './index'
const VRBox = function(deviceID) {
  if (!deviceID) {
    throw new Error('请传入设备ID')
  }

  const container = document.createElement('div')
  container.className = `vr-box-popup`
  const options = {
    deviceID,
    onClose: () => {
      container.remove()
    },
  }

  if (document.getElementById('NF-container')) {
    document.getElementById('NF-container').appendChild(container)
  } else {
    document.body.appendChild(container)
  }
  const vm = createVNode(AlertConstructor, options)
  vm.props.onDestroy = () => {
    console.log('vrBox销毁')
    render(null, container)
  }
  render(vm, container)
  return {
    close: () => container.remove()
  }
}
export default VRBox
