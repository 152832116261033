import request from '@/expand/request'

/**
 * 用户登录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userLogin = (params) => request.post('/bio/api/login', params)

/**
 * 注销用户登录
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userLogout = () => request.get('/bio/api/logout')

/**
 * 获取矢量文件
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getKmlFile = (params) => request.get('/bio/api/kml', params)

/**
 * 获取单位物种库
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAnimal = (params) => request.get('/bio/api/animal/unit', params)

/**
 * 获取物种库
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAllAnimal = (params) => request.get('/bio/api/animal/birds', params)

/**
 * 获取设备列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getDeviceList = (params) => request.get('/bio/api/device', params)

/**
 * 获取设备声音数据列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getVoiceList = (params) => request.get('/bio/api/voice', params)

/**
 * 获取设备的识别物种
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getDeviceAnimal = (params) => request.get('/bio/api/deviceAnimal', params)

/**
 * 获取同比统计数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getGrowStatistics = (params) => request.get('/bio/api/statistics/growStatistics', params)

/**
 * 获取数量统计
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCountStatistics = (params) => request.get('/bio/api/statistics/countStatistics', params)

/**
 * 获取时间线数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getVoiceLine = (params) => request.post('/bio/api/statistics/voiceLine', params)

/**
 * 获取按月份统计数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getValueByMonth = (params) => request.get('/bio/api/statistics/valueByMonth', params)

/**
 * 获取按物种统计数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getValueByAnimal = (params) => request.get('/bio/api/statistics/valueByAnimal', params)

/**
 * 获取按季度统计数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getValueBySeason = (params) => request.get('/bio/api/statistics/valueBySeason', params)

/**
 * 获取天气
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWeather = (params) => request.get('/bio/api/weather', params)

/**
 * 获取鉴定列表数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getIdentificationList = (params) => request.get('/bio/api/identification', params)

/**
 * 获取鉴定数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getIdentification = (params) => request.post('/bio/api/identification', params)

/**
 * 鉴定数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const identification = (params) => request.put('/bio/api/identification', params)

/**
 * 鉴定是否有鸟声数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const identificationStock = (params) => request.put('/bio/api/identification/stock', params)

/**
 * 获取鉴定库存数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getIdentificationStock = (params) => request.get('/bio/api/identification/stock', params)

/**
 * 修改鉴定库存数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateIdentificationStock = (params) => request.post('/bio/api/identification/stock', params)

/**
 * 根据条件获取统计设备识别数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const GetRecogByDevice = (params) => request.get('/bio/api/DeviceRecog/GetRecogByDevice', params)

/**
 * 根据条件查询设备识别数据的活动节律
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const GetDeviceRecogRhythm = (params) => request.get('/bio/api/DeviceRecog/DeviceRecogRhythm', params)
