<template>
  <div class="vr-display">
    <div class="vr-downloading" v-if="downloading">
      <p class="rd-container">正在下载全景图</p>
      <p>{{ downloadProgress }}%</p>
    </div>
    <div class="vr-viewer" :style="vrStyle" id="vrViewer"></div>
    <div class="extend-box">
      <div class="device-box" v-if="deviceInfo">
        <div class="name">{{ deviceInfo.name }}</div>
        <div class="intro-box">
          <div class="item">
            设备编号：<span class="val">{{ deviceInfo.name }}</span>
          </div>
          <div class="item">
            部署生境：<span class="val">{{ deviceInfo.remark || "-" }}</span>
          </div>
          <div class="item">
            部署经度：<span class="val">{{ deviceInfo.coordinates[0] }}</span>
          </div>
          <div class="item">
            部署纬度：<span class="val">{{ deviceInfo.coordinates[1] }}</span>
          </div>
        </div>
      </div>
      <div class="site-box" :class="!panelShow ? 'site-box-hide' : ''">
        <div class="show" @click="panelShow = !panelShow">
          <span v-if="!panelShow">监测站点</span>
        </div>
        <div class="top-box" ref="deviceBoxRef">
          <div
            v-for="item in regionalInfo.devices || []"
            :key="item.id"
            class="item"
            :class="item.id === deviceInfo.id ? 'sel' : ''"
            @click="handleSelectDevice(item)"
          >
            <div class="img-box">
              <img
                :src="`https://oss.sense-ecology.com/publish/device-photo/${item.old_id}/${item.old_id}.JPG`"
                alt=""
              />
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
        <div class="bottom-box">
          <div
            class="item"
            v-for="item in regionalData"
            :key="item.id"
            :class="item.id === regionalInfo.id ? 'sel' : ''"
            @click="handleSelectRegional(item)"
          >
            {{ item.name }}
          </div>
          <!-- <div class="item">白云山</div> -->
          <div class="more">
            <div class="m_btn m_prev" @click="handleChangeBoxScorll(-300)">
              <i class="icon-zhankai2"></i>
            </div>
            <div class="m_btn m_next" @click="handleChangeBoxScorll(300)">
              <i class="icon-zhankai2"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="audio-box" v-if="audioList.length">
        <div class="card">
          <div class="audio">
            <div class="sonogram">
              <img
                :src="audioList[currentIndex].recogSpectUrl"
                alt=""
                draggable="false"
              />
            </div>
            <div class="play"></div>
          </div>
          <div class="birds">
            <div class="item" v-if="audioList[currentIndex].animals.length">
              <img
                class="img"
                draggable="false"
                :src="`https://oss.sense-ecology.com/base/birds-photo/${audioList[currentIndex].animals[0].iD}.jpg`"
                alt=""
              />
              <div class="info">
                <div class="name">
                  {{ audioList[currentIndex].animals[0].name }}
                </div>
                <div class="lv">
                  识别率：
                  <i class="val"
                    >{{
                      (audioList[currentIndex].animals[0].score * 100).toFixed(
                        2
                      )
                    }}%
                  </i>
                </div>
              </div>
            </div>
            <div class="item" v-if="audioList[currentIndex].animals.length > 1">
              <img
                class="img"
                draggable="false"
                :src="`https://oss.sense-ecology.com/base/birds-photo/${audioList[currentIndex].animals[1].iD}.jpg`"
                alt=""
              />
              <div class="info">
                <div class="name">
                  {{ audioList[currentIndex].animals[1].name }}
                </div>
                <div class="lv">
                  识别率：
                  <i class="val"
                    >{{
                      (audioList[currentIndex].animals[1].score * 100).toFixed(
                        2
                      )
                    }}%
                  </i>
                </div>
              </div>
            </div>
            <div class="item" v-if="audioList[currentIndex].animals.length > 2">
              <img
                class="img"
                draggable="false"
                :src="`https://oss.sense-ecology.com/base/birds-photo/${audioList[currentIndex].animals[2].iD}.jpg`"
                alt=""
              />
              <div class="info">
                <div class="name">
                  {{ audioList[currentIndex].animals[2].name }}
                </div>
                <div class="lv">
                  识别率：
                  <i class="val"
                    >{{
                      (audioList[currentIndex].animals[2].score * 100).toFixed(
                        2
                      )
                    }}%
                  </i>
                </div>
              </div>
            </div>
            <div class="time">
              采集时间：{{ audioList[currentIndex].collectionTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Viewer } from "photo-sphere-viewer";
import { MarkersPlugin } from "photo-sphere-viewer/dist/plugins/markers";
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import "photo-sphere-viewer/dist/plugins/markers.css";
import { transformArrayBufferToBase64 } from "@/expand/util";
import store from "@/store";
import config from "@/config";
import { getVoiceList } from "_r/apis";

export default {
  name: "index",
  props: {
    deviceID: String,
  },

  data() {
    return {
      markersPlugin: null,
      resultShow: false,
      currentIndex: 0,
      animalInfo: {},
      audioList: [],
      deviceInfo: null,
      regionalInfo: {},
      panelShow: true,
      downloading: true,
      downloadProgress: 0,
      viewer: null,
      t1: null,
    };
  },
  computed: {
    // VR样式
    vrStyle() {
      return {
        width: `${store.state.system.displayArea.width}px`,
        height: `${store.state.system.displayArea.height}px`,
        transform: `scale(${
          config.calcRatioWidth / store.state.system.displayArea.width
        })`,
        transformOrigin: "top left",
      };
    },
    regionalData() {
      return store.state.userInfo.regionalData;
    },
  },
  methods: {
    handleYinFuShow() {
      for (let i = 1; i < 5; i++) {
        this.markersPlugin.showMarker(`yinfu-marker${i}`);
      }
    },
    handleYinFuHide() {
      for (let i = 1; i < 5; i++) {
        this.markersPlugin.hideMarker(`yinfu-marker${i}`);
      }
    },
    handleAudioEnd() {
      const delayCloseMS = 2000; // 延时关闭时间
      this.t1 = setTimeout(() => {
        this.currentIndex += 1;
        if (this.currentIndex >= this.audioList.length) {
          this.currentIndex = 0;
        }
        window.vrAudioObj.src = this.audioList[this.currentIndex].recogSoundUrl;
        window.vrAudioObj.play();
      }, delayCloseMS);
    },
    async handleLoadAudioData() {
      const res = await getVoiceList({
        filterNone: true,
        pageSize: 20,
        pageIndex: 1,
        deviceID: this.deviceInfo.id,
      });
      this.audioList = res.data;
    },
    async handleLoadAudio() {
      await this.handleLoadAudioData();
      if (!this.audioList.length) return;
      if (!window.vrAudioObj) {
        window.vrAudioObj = document.createElement("audio");
        window.vrAudioObj.addEventListener("ended", this.handleAudioEnd, true);
        window.vrAudioObj.addEventListener(
          "error",
          (error) => {
            console.error("加载失败", error);
            this.handleAudioEnd();
          },
          true
        );
      }
      window.vrAudioObj.src = this.audioList[this.currentIndex].recogSoundUrl;
      window.vrAudioObj.play();
    },
    handleGetDevice(id) {
      const self = this;
      let device = (() => {
        for (let i = 0; i < self.regionalData.length; i++) {
          for (let j = 0; j < self.regionalData[i].devices.length; j++) {
            const d = self.regionalData[i].devices[j];
            if (d.id === id) {
              self.regionalInfo = { ...self.regionalData[i] };
              return d;
            }
          }
        }
        return null;
      })();
      if (!device) device = this.regionalData[0].devices[0];
      this.deviceInfo = { ...device };
      this.handleLoadPanorama();
    },
    handleSelectDevice(item) {
      if (item.id === this.deviceInfo.id) return;
      if (window.vrAudioObj) window.vrAudioObj.pause();
      this.handleGetDevice(item.id);
    },
    async handleLoadPanorama() {
      try {
        this.downloading = true;
        this.downloadProgress = 0;
        const res = await axios.get(
          `https://oss.sense-ecology.com/publish/device-photo/${this.deviceInfo.old_id}/device.JPG`,
          {
            responseType: "arraybuffer",
            headers: { "cache-control": "no-cache" },
            onDownloadProgress: (progressEvent) => {
              this.downloadProgress = (
                (progressEvent.loaded / progressEvent.total) *
                100
              ).toFixed(2);
            },
          }
        );
        this.downloading = false;
        if (this.viewer) {
          this.viewer.setPanorama(
            `data:image/jpg;base64,${transformArrayBufferToBase64(res.data)}`,
            {
              showLoader: true,
              zoom: 1,
            }
          );
        } else {
          this.viewer = new Viewer({
            container: document.getElementById("vrViewer"),
            panorama: `data:image/jpg;base64,${transformArrayBufferToBase64(
              res.data
            )}`,
            loadingTxt: "正在加载全景",
            defaultLong: 0,
            defaultLat: 0,
            defaultZoomLvl: 1,
            navbar: false,
            autorotateDelay: 2000,
            autorotateSpeed: "0.25rpm",
            plugins: [
              [
                MarkersPlugin,
                {
                  markers: [],
                },
              ],
            ],
          });
        }
        await this.handleLoadAudio();
      } catch (e) {
        this.downloading = false;
        console.error(e);
        // alert('下载全景图失败')
      }
    },
    handleSelectRegional(item) {
      this.regionalInfo = item;
    },
    handleChangeBoxScorll(num) {
      this.$refs.deviceBoxRef.scrollLeft += num;
      // const val = this.$refs.deviceBoxRef.scrollLeft + num
      // const handle = () => {
      //   if (num < 0) {
      //     this.$refs.deviceBoxRef.scrollLeft -= 1
      //     if (this.$refs.deviceBoxRef.scrollLeft > val) {
      //       requestAnimationFrame(handle)
      //     }
      //   } else {
      //     this.$refs.deviceBoxRef.scrollLeft += 1
      //     if (this.$refs.deviceBoxRef.scrollLeft < val) {
      //       requestAnimationFrame(handle)
      //     }
      //   }
      // }
      // handle()
    },
  },
  async mounted() {
    store.commit("setMainBtnBack", true);
    this.handleGetDevice(this.deviceID);

    /*
    远景图：https://oss.sense-ecology.com/publish/device-photo/{deviceID}/far.JPG
    近景图：https://oss.sense-ecology.com/publish/device-photo/{deviceID}/device.JPG
    */
    // try {
    //   this.downloading = true
    //   const res = await axios.get(`https://oss.sense-ecology.com/publish/device-photo/${this.deviceInfo.id}/device.JPG`, {
    //     responseType: 'arraybuffer',
    //     headers: { 'cache-control': 'no-cache' },
    //     onDownloadProgress: (progressEvent) => {
    //       this.downloadProgress = (progressEvent.loaded / progressEvent.total * 100).toFixed(2)
    //     },
    //   })
    //   this.downloading = false
    //   viewer = new Viewer({
    //     container: document.getElementById('vrViewer'),
    //     panorama: `data:image/jpg;base64,${transformArrayBufferToBase64(res.data)}`,
    //     loadingTxt: '正在加载全景',
    //     defaultLong: 0,
    //     defaultLat: 0,
    //     defaultZoomLvl: 1,
    //     navbar: false,
    //     autorotateDelay: 2000,
    //     autorotateSpeed: '0.25rpm',
    //     plugins: [
    //       [MarkersPlugin, {
    //         markers: [],
    //       }],
    //     ],
    //   })
    //   this.handleLoadAudio()
    // } catch (e) {
    //   this.downloading = false
    //   console.error(e)
    //   // alert('下载全景图失败')
    // }
  },
  unmounted() {
    if (this.viewer) {
      this.viewer.destroy();
      this.viewer = undefined;
    }
    if (this.t1) clearTimeout(this.t1);
    if (window.vrAudioObj) {
      window.vrAudioObj.pause();
      window.vrAudioObj = undefined;
    }
    // store.commit('setMainBtnBack', false)
  },
};
</script>

<style lang="scss" scoped>
.vr-display {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 97;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(#000, 0.7);

  .vr-downloading {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    width: 140px;
    height: 140px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    &:before {
      content: "";
      width: 120px;
      height: 120px;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      border: 10px solid rgba(#fff, 0.8);
      border-right-color: transparent;
      animation: rotate360 3s linear infinite;
    }
  }

  .vr-viewer {
    width: 100%;
    height: 100%;
  }

  .extend-box {
    .device-box {
      padding: 30px 20px 20px 20px;
      position: absolute;
      left: 40px;
      top: 140px;
      width: 234px;
      height: 227px;
      background: url("~_a/news/vr/device-info.png") no-repeat 0 0;

      .name {
        font-size: 20px;
      }

      .intro-box {
        margin-top: 10px;

        .item {
          height: 36px;
          line-height: 36px;
          font-size: 14px;
          white-space: nowrap;

          .val {
            color: #24e38b;
          }
        }
      }
    }

    .site-box {
      position: absolute;
      right: 50%;
      bottom: 148px;
      height: 175px;
      transform: translateX(50%);

      //transition: all .2s ease;
      .show {
        width: 28px;
        height: 28px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        background: url("~_a/news/vr/close.png") no-repeat 0 0;
      }

      .top-box {
        width: 1813px;
        height: 134px;
        background: url("~_a/news/vr/panel-bg.png") no-repeat 0 0;
        display: flex;
        align-items: center;
        overflow-x: auto;
        padding: 0 15px;

        .item {
          cursor: pointer;
          margin: 0 15px;
          text-align: center;
          background: rgba(4, 29, 33, 0.5);
          border: 1px solid #24e38b;
          transition: all 0.2s ease;

          .img-box {
            width: 140px;
            height: 80px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block;
            }
          }

          .name {
            height: 28px;
            line-height: 28px;
          }

          &:hover {
            color: #24e38b;
            border-color: #24e38b;
          }
        }

        .sel {
          color: #24e38b;
          border-color: #24e38b;
        }
      }

      .bottom-box {
        width: 1813px;
        height: 42px;
        background: url("~_a/news/vr/tab-bg.png") no-repeat 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .item {
          margin: 0 20px;
          cursor: pointer;
          transition: color 0.2s ease;

          &:hover {
            color: #24e38b;
          }
        }

        .sel {
          color: #24e38b;
        }

        .more {
          position: absolute;
          top: 0px;
          bottom: 0px;
          right: 20px;
          display: flex;
          align-items: center;

          .m_btn {
            width: 28px;
            color: #24e38b;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border: 1px solid #24e38b;
            margin-left: 10px;
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            i {
              font-size: 12px;
            }
          }

          .m_prev {
            transform: rotate(90deg);
          }

          .m_next {
            transform: rotate(-90deg);
          }
        }
      }
    }

    .site-box-hide {
      right: 40px;
      transform: translateX(0);

      .show {
        width: 129px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        padding-right: 20px;
        cursor: pointer;
        background: rgba(4, 29, 33, 0.8);
        border: 1px solid #24e38b;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 28px;
          height: 28px;
          background: url("~_a/news/vr/open.png") no-repeat 0 0;
        }
      }

      .top-box,
      .bottom-box {
        display: none;
      }
    }

    .audio-box {
      position: absolute;
      top: 140px;
      right: 40px;

      .card {
        width: 250px;
        height: 396px;
        padding: 30px 15px 10px 15px;
        overflow: hidden;
        background: url("~@/assets/news/vr/audio-info.png") no-repeat 0 0;

        .audio {
          display: flex;
          align-items: center;

          .sonogram {
            width: 100%;
            height: 60px;
            background: url("~@/assets/mapData/audio-big-bg.png") no-repeat 0 0;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              height: 100%;
              width: 100%;
              flex-shrink: 0;
            }
          }

          .play {
            display: none;
            width: 24px;
            height: 24px;
            background: url("~@/assets/dynamicPerception/play.png") no-repeat 0
              0;
            background-size: cover;
            margin-left: 8px;
          }
        }

        .birds {
          margin-top: 10px;

          .item {
            position: relative;
            width: 100%;
            display: flex;
            margin-top: 22px;

            .img {
              width: 100px;
              height: 60px;
              object-fit: cover;
              margin-right: 10px;
              font-size: 0;
              flex-shrink: 0;
            }

            .info {
              font-size: 14px;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              white-space: nowrap;

              .lv {
                .val {
                  color: #24e38b;
                  font-weight: bold;
                }
              }
            }
          }

          .time {
            font-size: 14px;
            text-align: center;
            margin-top: 15px;
            font-style: italic;
            font-weight: bold;
            color: #ddd;
          }
        }
      }
    }
  }
}

@keyframes yinfuFrame {
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(-150px);
    opacity: 0;
  }
}

::v-deep .psv-container {
  background: rgba(#fff, 0.8);
}

::v-deep .yinfu-animate {
  position: relative;

  .ua-1 {
    &:before {
      content: "";
      opacity: 0.8;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      background: url("/data/yinfu/1.png") no-repeat 0 0;
      background-size: 100% 100%;
      width: 45px;
      height: 55px;
      animation: yinfuFrame 4s ease infinite;
    }

    &:after {
      content: "";
      display: block;
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      background: url("/data/yinfu/1.png") no-repeat 0 0;
      background-size: 100% 100%;
      width: 45px;
      height: 55px;
      animation: yinfuFrame 4s ease 1s infinite;
    }
  }

  .ua-2 {
    &:before {
      content: "";
      opacity: 0.8;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      background: url("/data/yinfu/2.png") no-repeat 0 0;
      background-size: 100% 100%;
      width: 55px;
      height: 50px;
      animation: yinfuFrame 4s ease infinite;
    }

    &:after {
      content: "";
      display: block;
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      background: url("/data/yinfu/2.png") no-repeat 0 0;
      background-size: 100% 100%;
      width: 55px;
      height: 50px;
      animation: yinfuFrame 4s ease 1s infinite;
    }
  }

  .ua-3 {
    &:before {
      content: "";
      opacity: 0.8;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      background: url("/data/yinfu/3.png") no-repeat 0 0;
      background-size: 100% 100%;
      width: 29px;
      height: 55px;
      animation: yinfuFrame 4s ease infinite;
    }

    &:after {
      content: "";
      display: block;
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      background: url("/data/yinfu/3.png") no-repeat 0 0;
      background-size: 100% 100%;
      width: 29px;
      height: 55px;
      animation: yinfuFrame 4s ease 1s infinite;
    }
  }

  .ua-4 {
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      background: url("/data/yinfu/4.png") no-repeat 0 0;
      background-size: 100% 100%;
      width: 32px;
      height: 55px;
      animation: yinfuFrame 4s ease infinite;
    }

    &:after {
      content: "";
      display: block;
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      background: url("/data/yinfu/4.png") no-repeat 0 0;
      background-size: 100% 100%;
      width: 32px;
      height: 55px;
      animation: yinfuFrame 4s ease 1s infinite;
    }
  }
}

::v-deep .psv-tooltip {
  background-color: transparent;
  max-width: initial;
  padding: 0;
  border-radius: 0;
  outline: none;
  box-shadow: none;

  .psv-tooltip-content {
    text-shadow: none;

    .device-modal {
      width: 452px;
      height: 324px;
      padding: 20px 30px;
      opacity: 0.8;
      background: url("~@/assets/mapData/bubble_bg.png") repeat-y 0;

      .tb-name {
        font-size: 14px;
        font-weight: bold;
      }

      .tb-info {
        display: flex;
        align-items: center;
        font-size: 12px;

        .bi-photo {
          //width: 162px;
          //height: 108px;
          width: 81px;
          height: 54px;
          line-height: 54px;
          text-align: center;
          flex-shrink: 0;
          position: relative;
          cursor: pointer;

          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }

          &:before {
            content: "进入全景图 >";
            text-decoration: underline;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#000, 0.7);
            opacity: 0;
          }

          &:hover {
            &:before {
              opacity: 1;
            }
          }
        }

        .bi-info {
          white-space: nowrap;

          .ii-item {
            display: flex;
            line-height: 22px;

            .ii-label {
              //width: 80px;
              width: 46px;
              text-align: left;
              margin-right: 10px;
            }
          }

          .ii-high {
            color: #23d59c;
          }
        }
      }

      .tb-intro {
        margin-top: 6px;
        background-color: #09383f;
        padding: 6px;
        height: 50px;
        font-size: 12px;
      }
    }

    .intro-modal {
      width: 422px;
      opacity: 0.8;
      transform-origin: center center;

      &:before {
        content: "";
        display: block;
        background: url("~@/assets/vr/marker-intro-top.png") no-repeat 0;
        width: 422px;
        height: 25px;
      }

      .om-box {
        width: 422px;
        padding: 10px 15px;
        margin: -1px 0;
        background: url("~@/assets/vr/marker-intro.png") repeat-y 0;

        .tb-name {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .bi-info {
          white-space: nowrap;

          .ii-item {
            display: flex;
            line-height: 22px;

            .ii-label {
              width: 70px;
              text-align: left;
              margin-right: 10px;
            }
          }

          .ii-high {
            color: #23d59c;
          }
        }

        .tb-intro {
          margin-top: 6px;
          background-color: #09383f;
          padding: 6px;
          height: 50px;
          font-size: 12px;
        }
      }

      &:after {
        content: "";
        display: block;
        background: url("~@/assets/vr/marker-intro-bottom.png") no-repeat 0;
        width: 422px;
        height: 25px;
      }
    }
  }

  .psv-tooltip-arrow {
    display: none;
  }
}

::v-deep .marker-title-box {
  height: 48px;
  display: flex;
  transform-origin: center top;

  &:before {
    content: "";
    display: inline-block;
    width: 44px;
    height: 48px;
    opacity: 0.7;
    background: url("~_a/vr/marker-title-left.png") no-repeat 0 0;
  }

  .rt-name {
    height: 48px;
    line-height: 48px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0.7;
      background: url("~_a/vr/marker-title.png") repeat-x 0 0;
    }
  }

  &:after {
    content: "";
    display: inline-block;
    width: 44px;
    height: 48px;
    opacity: 0.7;
    background: url("~_a/vr/marker-title-right.png") no-repeat 0 0;
  }
}
</style>
