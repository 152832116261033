// eslint-disable-next-line import/no-cycle
import request from '@/expand/request';
import store from "@/store";

/**
 * 获取单位所有动物信息
 * @returns {Promise<T|*[]|boolean>}
 */
export const getUnitAnimalInfo = (animalId) => request.get('/api/repository/animal/getUnitAnimalInfo', { unitId: store.state.userInfo.unit_id, animalId });

/**
 * 获取单位所有植物信息
 * @returns {Promise<T|*[]|boolean>}
 */
export const getUnitPlantInfo = (plantId) => request.get('/api/repository/Plant/GetUnitPlantInfo', { unitId: store.state.userInfo.unit_id, plantId });
