// eslint-disable-next-line import/no-cycle
import { getUnitAnimalInfo, getUnitPlantInfo } from '_r/diversity';
import {getAnimal} from '_r/apis';

export default {
  state: () => ({
    plants: [], // 植物库
    animals: [], // 动物库
    plantUpdateTime: null, // 植物库更新时间
    animalUpdateTime: null, // 动物库更新时间
  }),

  mutations: {

    /**
     * 加入植物库
     * @param state
     * @param data 植物库数据集合（数组）
     * 流程：循环加入数据，如果原来库里存在则替换，不存在新加入
     */
    setPlant(state, data) {
      console.log(data);
      if (state.plants.length) {
        data.forEach((item) => {
          const index = state.plants.findIndex((p) => p.id === item.id);
          if (index === -1) {
            state.plants.push(item);
          } else {
            state.plants[index] = item;
          }
        });
      } else {
        state.plants = data;
      }
      state.plantUpdateTime = new Date();
    },

    /**
     * 加入动物库
     * @param state
     * @param data 动物库数据集合（数组）
     * 流程：循环加入数据，如果原来库里存在则替换，不存在新加入
     */
    setAnimal(state, data) {
      // if (state.animals.length) {
      //   data.forEach((item) => {
      //     const index = state.animals.findIndex((a) => a.id === item.id);
      //     if (index === -1) {
      //       state.animals.push(item);
      //     } else {
      //       state.animals[index] = item;
      //     }
      //   });
      // } else {
      //   state.animals = data;
      // }
      state.animals = data;
      state.animalUpdateTime = new Date();
    },

    /**
     * 清空物种库数据
     * @param state
     */
    clearSpeciesBank(state) {
      state.plants = [];
      state.animals = [];
      state.plantUpdateTime = null;
      state.animalUpdateTime = null;
    },

    /**
     * 添加动物
     * @param state
     */
    addAnimal(state, animal) {
      state.animals.push(animal);
    },

    /**
     * 添加植物
     * @param state
     */
    addPlant(state, obj) {
      state.plants.push(obj[0]);
    },

    /**
     * 更新单个物种
     * @param state
     * @param animal
     */
    updateAnimal(state, animal) {
      const index = state.animals.findIndex((a) => a.keyAnimalID === animal.keyAnimalID);
      if (index === -1) {
        state.animals.push({...animal})
      } else {
        state.animals[index] = {...animal}
      }
    }
  },

  getters: {
    /**
     * 通过ID集合获取植物库数据
     * @param state
     * @returns {function(*): *[]}
     */
    getPlantById(state) {
      /**
       * @param ids 植物库ID集合（数组）
       * @returns {[]}
       */
      return (ids) => state.plants.filter((p) => ids.include(p.id));
    },

    /**
     * 通过ID集合获取动物库数据
     * @param state
     * @returns {function(*): *[]}
     */
    getAnimalById(state) {
      /**
       * @param ids 动物库ID集合（数组）
       * @returns {[]}
       */
      return (id) => state.animals.find((a) => a.keyAnimalID === id);
    },
  },

  actions: {

    /**
     * 根据动物ID更新动物信息
     * @param commit
     * @param animalID
     * @returns {Promise<void>}
     */
    async updateAnimalByID({commit}, animalID) {
      const res = await getAnimal({animalID})
      if (res.messageType === 0 && res.animals && res.animals.length) {
        commit('updateAnimal', res.animals[0])
        return res.animals[0]
      }
      return {}
    },
    /**
     * 更新植物库
     * @param commit
     * @param rootState
     * @returns {Promise<void>}
     */
    async updatePlants({ commit }) {
      // 待接入API
      // console.log(rootState);
      const animalList = await getUnitAnimalInfo();
      if (animalList) {
        commit('setAnimal', animalList);
      }
      const plantList = await getUnitPlantInfo();
      if (plantList) {
        commit('setPlant', plantList);
      }
      console.log(animalList, plantList);
    },

    /**
     * 更新动物库
     * @param commit
     * @returns {Promise<void>}
     */
    async updateAnimals({ commit }) {
      // 待接入API
      commit('setAnimal', []);
    },

    /**
     * 通过ID或者name获取动物数据
     * @param state
     * @param commit
     * @param data   传入{id:''}，通过Id查动物信息，物种库有直接返回，物种库没有，直接调接口查数据返回，并添加到本地物种库，传入{name:''}通过name查询数据并返回
     * @returns {Promise<void>}
     */
    async getAnimalInfo({ commit, state }, data) {
      let arr = {};
      if (data.name) {
        arr = state.animals.filter((v) => v.name === data.name);
      } else {
        const flag = state.animals.some((v) => v.id === data.id);
        if (!flag) {
          const res = await getUnitAnimalInfo(data.id);
          arr = { ...res.data.animals };
          commit('addAnimal', arr);
        } else {
          arr = state.animals.filter((v) => v.id === data.id);
        }
      }
      return arr;
    },

    /**
     * 通过ID或者name获取植物数据
     * @param state
     * @param commit
     * @param data   传入{id:''}，通过Id查动物信息，物种库有直接返回，物种库没有，直接调接口查数据返回，并添加到本地物种库，传入{name:''}通过name查询数据并返回
     * @returns {Promise<void>}
     */
    async getPlantInfo({ commit, state }, data) {
      let arr = [];
      if (data.name) {
        arr = state.plants.filter((v) => v.name === data.name);
      } else {
        const flag = state.plants.some((v) => v.id === data.id);
        if (!flag) {
          const res = await getUnitPlantInfo(data.id);
          arr = { ...res.data.plants };
          commit('addPlant', arr);
        } else {
          arr = state.plants.filter((v) => v.id === data.id);
        }
      }
      return arr;
    },

  },
};
