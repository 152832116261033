<template>
  <div id="displayArea" class="display-area" :style="styles">
    <video src="@/assets/bg.mp4" autoplay loop class="ya-bg" muted></video>
    <router-view/>
  </div>
</template>

<script>
import screenfull from 'screenfull';

export default {
  name: 'displayArea',
  data() {
    return {};
  },
  computed: {
    styles() {
      const { proportion, top, left } = this.$store.state.system.displayArea;
      return {
        transform: `scale(${proportion})`,
        width: `${this.$config.calcRatioWidth}px`,
        height: `${this.$config.calcRatioHeight}px`,
        top: `${top}px`,
        left: `${left}px`,
      };
      // const {
      //   top, left, width, height,
      // } = this.$store.state.system.displayArea;
      // return {
      //   top: `${top}px`,
      //   left: `${left}px`,
      //   width: `${width}px`,
      //   height: `${height}px`,
      // };
    },
  },
  methods: {
    /**
     * 全屏切换
     */
    fullScreenSwitch() {
      // if (screenfull.isEnabled) {
      //  screenfull.toggle();
      // } else {
      //  console.log('不支持全屏');
      // }
      if (screenfull.enabled) {
        screenfull.toggle();
      } else {
        console.log('不支持全屏');
      }
    },
  },
  beforeCreate() {
    this.$store.commit('calcDisplayArea');
    // 可视区域大小变更
    window.onresize = () => {
      this.$store.commit('calcDisplayArea');
    };
    // 禁止右键菜单
    window.document.oncontextmenu = () => false;
  },
  mounted() {
    if (this.$config.defaultFullScreen) {
      this.fullScreenSwitch();
    }
  },
};
</script>

<style lang="scss" scoped>
.display-area {
  position: relative;
  top: 0;
  left: 0;
  background-color: #ffffff;
  transform-origin: left top;
  .ya-bg {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    background-color: #000000;
  }
}
</style>
