import { createRouter, createWebHistory } from 'vue-router'
import Login from '_v/login/Login.vue'
import MainForm from '_c/base/MainForm.vue'
import store from '@/store'

const routes = [
  // {
  //   path: '/',
  //   name: 'login',
  //   component: Login
  // },
  // {
  //   path: '/nav',
  //   name: 'nav',
  //   component: () => import('_v/navigation/Navigation')
  // },
  {
    path: '/',
    name: 'main',
    component: MainForm,
    children: [
      {
        path: '',
        name: 'mapData',
        component: () => import('_v/mapData/MapData')
      },
      // {
      //   path: 'dynamicPerception',
      //   name: 'dynamicPerception',
      //   component: () => import('_v/dynamicPerception/DynamicPerceptionAudio')
      // },
      // {
      //   path: 'panorama',
      //   name: 'panorama',
      //   component: () => import('_v/VRDisplay/index')
      // },
      // {
      //   path: 'speciesComposition',
      //   name: 'speciesComposition',
      //   component: () => import('_v/analysisStatistics/SpeciesComposition')
      // },
      // {
      //   path: 'activityRhythm',
      //   name: 'activityRhythm',
      //   component: () => import('_v/analysisStatistics/ActivityRhythm')
      // },
      // {
      //   path: 'biodiversityIndex',
      //   name: 'biodiversityIndex',
      //   component: () => import('_v/exponential/BiodiversityIndex')
      // },
      // {
      //   path: 'nearNaturalnessIndex',
      //   name: 'nearNaturalnessIndex',
      //   component: () => import('_v/exponential/NearNaturalnessIndex')
      // },
      // {
      //   path: 'vrDisplay',
      //   name: 'vrDisplay',
      //   component: () => import('_v/VRDisplay/VRDisplay')
      // },
      // {
      //   path: 'relativeAbundance',
      //   name: 'relativeAbundance',
      //   component: () => import('_v/analysisStatistics/RelativeAbundance')
      // },
      // {
      //   path: 'photoSphereViewer',
      //   name: 'photoSphereViewer',
      //   component: () => import('_v/mapData/PhotoSphereViewer')
      // }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.state.userInfo.session) {
    next('/')
  } else {
    next();
  }
});

export default router
