<template>
  <div class="main-form">
    <div class="nf-header">
      <div class="box animated slideInDown">
        <div class="company"></div>
        <div class="platformName title">
          <p class="cn">{{ platformName.cn }}</p>
          <!-- <p class="en">{{ platformName.en }}</p> -->
        </div>
        <div class="info">
          <div class="weather" v-if="weatherInfo">
            <span>{{ weatherInfo.city }}</span>
            <span style="margin-left: 5px">{{ weatherInfo.temperature }}°C</span>
            <img draggable="false" :src="`/weather/${weatherInfo.img}.png`" class="icon">
            <span>{{ weatherInfo.weather }}</span>
          </div>
          <!--          <span class="account" :title="userName">{{ userName }}</span>-->
          <!-- <span class="logout" @click="logout">退出登录</span> -->
          <div class="fullscreen iconfont" :class="screenfullStatus ? 'icon-banping' : 'icon-quanping'"
            @click="fullScreenSwitch"></div>
        </div>
      </div>
    </div>
    <div class="nf-container" id="NF-container">
      <router-view />
    </div>
    <div class="nf-footer animated slideInUp">
      <div class="nav-btn">
        <div class="btns" v-if="$store.state.system.mainButtonBack">
          <div @click="handleMainBack()" class="btn" style="top: -10px">返回</div>
        </div>
        <template v-else>
          <!--圆直径4677-->
          <div v-for="(item, index) in navList" class="btns" :class="item.bg ? `bg-${item.bg}` : ''"
            :style="`transform: rotate(${transforms[index][0]}deg) translateY(${transforms[index][1]}px)`"
            :key="item.routeName" @mouseover="showChildNav = item.routeName" @mouseleave="showChildNav = null">
            <template v-if="item.items && item.items.length">
              <transition name="fade">
                <div class="children" v-show="showChildNav === item.routeName" style="animation-duration: 0.3s">
                  <div v-for="(child) in item.items" @click="handleNavTo(child)" class="btn"
                    :class="activeNav[1] === child.routeName ? 'active' : ''" :key="child.routeName">{{ child.title }}
                  </div>
                </div>
              </transition>
              <div class="btn" :class="activeNav[0] === item.routeName ? 'active' : ''">{{ item.title }}</div>
            </template>
            <div @click="handleNavTo(item)" class="btn" :class="activeNav[0] === item.routeName ? 'active' : ''" v-else>
              {{ item.title }}
            </div>
          </div>
        </template>
      </div>
      <div class="actions">
        <div class="voice btn">
          <i class="iconfont icon-yuyin"></i>
        </div>
        <div class="set btn">
          <i class="iconfont icon-shezhi1"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import { userLogout } from '@/api/apis'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'MainForm',
  data() {
    return {
      transforms: [],
      screenfullStatus: false,
      showChildNav: null,
    }
  },
  computed: {

    /**
     * 选中的导航
     * */
    activeNav() {
      const actives = [this.$route.name]
      if (this.$route.matched.length > 2) {
        actives.unshift(this.$route.matched[1].name)
      }
      return actives
    },
    ...mapState({
      weatherInfo: state => state.userInfo.weatherInfo,
      userName: state => state.userInfo.nickName,
      platformName: state => state.userInfo.platformName,
      navList: state => state.userInfo.navList,
    }),
  },
  methods: {
    /**
     * 获取天气
     */
    async getWeather() {
      await this.$store.dispatch('getWeather')
      setTimeout(this.getWeather, 1000 * 60 * 30)// 半小时获取一次
    },
    /**
     * 退出登录
     */
    logout() {
      userLogout()
      window.localStorage.clear()
      this.$store.commit('clearSpeciesBank')
      this.$store.commit('clearUserInfo')
      this.$router.replace({ name: 'login' })
    },
    /**
     * 全屏切换
     */
    fullScreenSwitch() {
      if (screenfull.isEnabled) {
        screenfull.toggle()
        this.screenfullStatus = !this.screenfullStatus
      } else {
        console.log('不支持全屏')
      }
    },
    /**
     * 跳转指定导航
     * @param item
     */
    handleNavTo(item) {
      if (this.activeNav[this.activeNav.length - 1] !== item.routeName) {
        this.$router.push({ name: item.routeName })
      }
    },
    /**
     * 自定义返回按钮返回
     */
    handleMainBack() {
      this.$store.commit('setMainBtnBack', false)
      if (window.vrAudioObj) {
        window.vrAudioObj.pause()
        window.vrAudioObj = undefined
      }
      document.getElementsByClassName('vr-box-popup')[0].remove()
    }
  },
  beforeCreate() {
    // 禁止全屏快捷键 F11
    document.addEventListener('keydown', (event) => {
      if (event.key === 'F11') {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = false
        return false
      }
      return true
    })
  },
  created() {
    const count = this.navList.length
    const transforms = []
    if (count % 2 === 0) {
      const ts = [[1, 1], [3, 9], [5, 26]] // 旋转位移规律
      const center = count / 2
      for (let i = 0; i < count; i += 1) {
        if (i < center) {
          transforms.push([-ts[center - i - 1][0], ts[center - i - 1][1]])
        } else {
          transforms.push(ts[i - center])
        }
      }
    } else {
      const center = Math.floor(count / 2)
      const ts = [[2, 10], [4, 21], [6, 42]] // 旋转位移规律
      for (let i = 0; i < count; i += 1) {
        if (i < center) {
          transforms.push([-ts[center - i - 1][0], ts[center - i - 1][1]])
        } else if (i > center) {
          transforms.push(ts[i - center - 1])
        } else {
          transforms.push([0, 0])
        }
      }
    }
    this.transforms = transforms
    this.getWeather();
  },
}
</script>

<style lang="scss" scoped>
.main-form {
  width: 100%;
  height: 100%;
  background: url("~_a/main/container-bg.png") no-repeat 0 36px;
  position: relative;
  overflow: hidden;
  font-size: 16px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 98;
    width: 100%;
    height: 146px;
    background: url("~_a/main/head-bg.png") no-repeat 0 0;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 98;
    width: 100%;
    height: 85px;
    background: url("~_a/main/foot-bg.png") no-repeat 0 0;
  }

  .nf-header {
    position: absolute;
    left: 0;
    top: 16px;
    width: 100%;
    z-index: 99;

    .box {
      width: 1700px;
      height: 77px;
      margin: 0 auto;
      position: relative;
      z-index: 9;
      background: url("~_a/main/header-bg.png") no-repeat center 0;
      background-size: contain;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 60px;

      .company {
        width: 303px;
        height: 33px;
        margin-bottom: 10px;
        background: url("~_a/main/company.png") no-repeat 0 0;
      }

      .title {
        width: 720px;
        position: absolute;
        left: 50%;
        top: 6px;
        transform: translateX(-50%);

        .cn {
          font-size: 30px;
          letter-spacing: 5px;
        }

        .en {
          font-size: 14px;
          letter-spacing: 1px;
        }
      }

      .info {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin-right: -40px;

        .weather {
          display: flex;
          align-items: center;

          .icon {
            margin: 0 5px 0 15px;
            display: inline-block;
            width: 30px;
            height: 30px;
          }
        }

        .account {
          margin: 0 15px;
          max-width: 120px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .logout {
          text-decoration: underline;
          cursor: pointer;
        }

        .fullscreen {
          margin-left: 20px;
          width: 40px;
          height: 40px;
          line-height: 40px;
          background: rgba(#fff, .2);
          border-radius: 2px;
          cursor: pointer;
          text-align: center;
          font-size: 28px;
        }
      }
    }
  }

  .nf-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .nf-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;

    .nav-btn {
      display: flex;
      margin-bottom: 2px;

      .btns {
        position: relative;

        .children {
          position: absolute;
          left: 0;
          bottom: 70px;
          width: 100%;

          &:before {
            content: "";
            display: block;
            position: absolute;
            left: 46px;
            right: 46px;
            top: 35px;
            bottom: -35px;
            background-color: rgba(#052031, .8);
            box-shadow: 0 0 40px 30px rgba(#052031, .8);
          }

          .btn {
            margin-bottom: 4px;

            &:hover {
              color: #00FFC4;
              background: url("~_a/main/nav-btn-active.png") no-repeat 0 0;
            }
          }
        }

        &:hover {
          &>.btn {
            color: #00FFC4;
            background: url("~_a/main/nav-btn-active.png") no-repeat 0 0;
          }
        }

        .btn {
          width: 217px;
          height: 70px;
          line-height: 70px;
          font-weight: bold;
          text-align: center;
          margin: 0 16px;
          cursor: pointer;
          background: url("~_a/main/nav-btn.png") no-repeat 0 0;
          transition: all .2s ease;
          position: relative;
          z-index: 99;
        }

        .active {
          color: #00FFC4;
          background: url("~_a/main/nav-btn-active.png") no-repeat 0 0;
        }
      }

      .bg-2 {
        .btn {
          width: 257px;
          height: 85px;
          line-height: 85px;
          letter-spacing: 10px;
          text-indent: 10px;
          font-size: 18px;
          font-weight: bold;
          background: url("~_a/main/nav-btn2.png") no-repeat 0 0;
        }

        &:hover {
          &>.btn {
            background: url("~_a/main/nav-btn-active2.png") no-repeat 0 0;
          }
        }

        .active {
          background: url("~_a/main/nav-btn-active2.png") no-repeat 0 0;
        }
      }
    }

    .actions {
      visibility: hidden;
      width: 258px;
      height: 37px;
      background: url("~_a/main/botton-action.png") no-repeat 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1CE0A7;

      .btn {
        width: 70px;
        height: 32px;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        transition: opacity .2s ease;

        .iconfont {
          font-size: 20px;
        }

        &:hover {
          opacity: .7;
        }
      }

      .voice {}

      .set {}
    }
  }
}
</style>
