export default {
  // 是否测试环境
  debug: true,
  // 平台标题
  title: '广东石门台自然保护区动物声学感知平台',
  // 物种缓存信息名称
  speciesCacheStorageKey: 'speciesCacheData',
  // API请求地址（正式）
  apiBaseUrl: '',
  // API请求地址（测试）
  // apiBaseUrlTest: 'http://test.sense-ecology.com',
  apiBaseUrlTest: 'https://publish.sense-ecology.com',
  // apiBaseUrlTest: 'http://192.168.41.154:9001',
  // apiBaseUrlTest: 'http://39.108.108.215:80',
  // 平台Session（请求接口用）
  appSession: 'ef1b501a7d604b0fa78ed54974a4e603',
  // 平台版本
  appVersion: '1.0',
  // 项目Session（请求接口用）
  // proSession: '231d64f4765e49009d6d1657c5fdb89c',
  proSession: 'b0b78949b9554b1ba71573e5812ab6e2',
  // 项目版本
  proVersion: '1.0',
  // 计算分辨率宽度
  calcRatioWidth: 1920,
  // 计算分辨率高度
  calcRatioHeight: 1080,
  // 是否默认全屏
  defaultFullScreen: true,
};
