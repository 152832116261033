import axios from 'axios';
import config from '../config';
import store from '../store';

const apiInstance = axios.create({
  baseURL: config.debug ? config.apiBaseUrlTest : config.apiBaseUrl,
  headers: {
    'x-sense-app-session': config.appSession,
    'x-sense-app-version': config.appVersion,
    'x-sense-pro-session': config.proSession,
    'x-sense-pro-version': config.proVersion,
  },
});

// 统一请求拦截
apiInstance.interceptors.request.use((_config) => {
  _config.headers['x-sense-user-session'] = store.state.userInfo.session || ''; // 填充登录信息凭证
  _config.cancelToken = new axios.CancelToken((cancel) => {
    store.commit('PUSH_CANCEL', {
      cancelToken: cancel,
    });
  });

  if (_config.method === 'get') {
    _config.data = { unused: 0 };
  }
  return _config;
}, (error) => Promise.reject(error));

// 统一响应拦截
apiInstance.interceptors.response.use((response) => {
  // console.log(response.config.url, JSON.stringify(response.data))
  return response.data
}, (error) => Promise.reject(error));

/**
 diversity  多样性调查
 iot 物联网
 partner 合作单位
 share 公共api
 */
export default {
  post: (url, data) => apiInstance.post(url, data),
  get: (url, params) => apiInstance.get(url, { params }),
  put: (url, data) => apiInstance.put(url, data),
  del: (url, params) => apiInstance.delete(url, { params }),
};
