import { createApp } from 'vue'
import * as echarts from 'echarts'
import App from './App.vue'
import router from './router'
import store from './store'
import config from './config';
import Alert from '_c/popup/alert/alert'
import VRBox from '_c/popup/vrBox/vrBox'
import '@/expand/style/iconfont.css';
import '@/expand/style/animate.min.css'

const app = createApp(App).use(store).use(router)
app.config.globalProperties.$config = config
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$alert = Alert
app.config.globalProperties.$vrBox = VRBox
app.directive('clickOutside', {
  mounted(el, binding, node) {
    el.event = (event) => {
      // 判断当前点击是否非Dom范围
      if (el !== event.target && !el.contains(event.target)) {
        binding.value(event);
      }
    };
    document.body.addEventListener('click', el.event, true);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.event, true);
  }
})
app.directive('audioPlayer', {
  mounted(el, binding) {
    el.classList.add('audio-play-mask')
    const clearPauseEl = () => {
      const playEl = document.querySelector('.audio-pause-mask')
      if (playEl) {
        playEl.classList.remove('audio-pause-mask')
        playEl.classList.add('audio-play-mask')
      }
    }
    el.onclick = () => {
      if (el.classList.contains('audio-pause-mask')) {
        el.classList.remove('audio-pause-mask')
        el.classList.add('audio-play-mask')
        if (window.globalBackgroundAudio) window.globalBackgroundAudio.pause()
      } else {
        clearPauseEl()
        el.classList.remove('audio-play-mask')
        el.classList.add('audio-pause-mask')
        if (!window.globalBackgroundAudio) {
          window.globalBackgroundAudio = document.createElement('AUDIO')
          window.globalBackgroundAudio.addEventListener('ended', clearPauseEl, false)
        } else {
          window.globalBackgroundAudio.pause()
        }
        window.globalBackgroundAudio.setAttribute('src', binding.value)
        window.globalBackgroundAudio.load()
        window.globalBackgroundAudio.play()
      }
    }
  }
})
app.mount('#app')
