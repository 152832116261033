import config from '../../config'

export default {
  state: () => ({
    displayArea: {
      top: 0, // 显示区域距离顶部
      left: 0, // 显示区域距离左部
      width: 0, // 显示区域宽度
      height: 0, // 显示区域高度
      proportion: 1, // 缩放比例
    },
    mainButtonBack: false,
    cancelTokenArr: [],
  }),
  mutations: {
    PUSH_CANCEL(state, payload) {
      state.cancelTokenArr.push(payload.cancelToken)
    },
    CLEAR_CANCEL(state) {
      state.cancelTokenArr.forEach((cancel) => {
        if (cancel !== null) {
          cancel('interrupt')
        }
      })
      state.cancelTokenArr = []
    },
    /**
     * 计算显示区域信息
     * @param state
     */
    calcDisplayArea2(state) {
      const { clientWidth, clientHeight } = document.body
      const op = config.calcRatioWidth / config.calcRatioHeight // 原始比例
      let width = clientWidth  // 屏幕宽
      let height = clientWidth / op  // 当前宽算出预计高
      let top = 0
      let left = 0
      let proportion = width / config.calcRatioWidth
      if (height > clientHeight) {
        width = clientHeight * op
        height = clientHeight
        proportion = width / config.calcRatioWidth
        left = ((clientWidth - width)) / 2
      } else {
        top = ((clientHeight - height)) / 2
      }
      state.displayArea = {
        top,
        left,
        width,
        height,
        proportion,
      }
    },
    /**
     * 计算显示区域信息
     * @param state
     */
    calcDisplayArea(state) {
      const { clientWidth, clientHeight } = document.body
      const op = config.calcRatioWidth / config.calcRatioHeight // 原始比例
      const current_op = clientWidth / clientHeight
      const displayArea = { top: 0, left: 0, width: 0, height: 0, proportion: 0 }
      if (current_op > op) {
        displayArea.width = clientHeight * op
        displayArea.height = clientHeight
        displayArea.left = (clientWidth - displayArea.width) / 2
        displayArea.proportion = clientHeight / config.calcRatioHeight
      } else if (current_op < op) {
        displayArea.width = clientWidth
        displayArea.height = clientWidth / op
        displayArea.top = (clientHeight - displayArea.height) / 2
        displayArea.proportion = clientWidth / config.calcRatioWidth
      } else {
        displayArea.width = clientWidth
        displayArea.height = clientHeight
        displayArea.proportion = clientWidth / config.calcRatioWidth
      }
      state.displayArea = displayArea
    },
    setMainBtnBack(state, status) {
      state.mainButtonBack = status
    }
  },
  getters: {
    /**
     * 计算像素比例
     * @param state
     * @param pixel 像素值
     * @returns {function(*)}
     */
    calcPixelScale: (state) => (pixel) => pixel * state.displayArea.proportion,
  },
}
