<template>
  <div id="app">
    <template v-if="$route.name === 'appraisal'">
      <router-view />
    </template>
    <template v-else>
      <DisplayArea>
        <keep-alive>
          <router-view />
        </keep-alive>
      </DisplayArea>
    </template>
  </div>
</template>

<script>
import DisplayArea from '_c/base/DisplayArea'

export default {
  components: { DisplayArea },
  created(){
    this.$store.commit('setMainBtnBack', false)
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  user-select: none;
  box-sizing: border-box;
}

#app {
  font-family: Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif;
  color: #ffffff;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #000000;
}

::-webkit-scrollbar {
  width: 6px;
  height: 1px;
}

::-webkit-scrollbar-thumb { //滑块部分
  border-radius: 6px;
  background-color: rgba(#7f8a91, .6);
}

::-webkit-scrollbar-track { //轨道部分
  background: rgba(0, 0, 0, 0);

}

::-webkit-input-placeholder {
  color: #eeeeee;
}

@font-face {
  font-family: "fradm";
  src: url("~@/expand/font/FRADM.TTF") format('truetype');
}


@-webkit-keyframes centerFadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -20px);
    transform: translate(-50%, -20px)
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
  }
}

.centerFadeInDown {
  -webkit-animation-name: centerFadeInDown;
  animation-name: centerFadeInDown
}

@-webkit-keyframes centerFadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -20px);
    transform: translate(-50%, -20px)
  }
}


@keyframes rotate360 {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}

.centerFadeOutUp {
  -webkit-animation-name: centerFadeOutUp;
  animation-name: centerFadeOutUp
}

.platformName {
  p {
    text-align: center;
    font-size: 42px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    background-image: linear-gradient(0deg, #FFFFFF 17.4072265625%, #0ED2BD 98.1201171875%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 10px;
  }
}

// 音频播放遮罩
.audio-play-mask {
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url('~_a/mapData/play.png') no-repeat 0;
    opacity: .6;
    transition: opacity .2s ease;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

// 音频暂停遮罩
.audio-pause-mask {
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url('~_a/mapData/pause.png') no-repeat 0;
  }
}

</style>
