import config from '@/config/index'
import { getWeather } from '_r/apis'
import regionalData from '@/data/regionalData'
export default {
  state: () => ({
    prolandID: '',  //1372068770989871104
    session: '5c89927da9e04c92b9cea4c73b3c6f9a',
    nickName: '',
    platformName: {
      en: 'Ecological monitoring and perception platform of Ruyuan Grand Canyon',
      cn: config.title
    },
    navList: [
      // { title: '物种组成分析', routeName: 'speciesComposition' },
      // { title: '生物多样性评价', routeName: 'biodiversityIndex' },
      // { title: '近自然度评价', routeName: 'nearNaturalnessIndex' },
      // { title: '首页', routeName: 'dynamicPerception', bg: '2' },
      // { title: '全景声像监控', routeName: 'vrDisplay' },
      // { title: '活动节律分析', routeName: 'activityRhythm' },
      // { title: '监测站点管理', routeName: 'mapData' },
      // { title: '首页', routeName: 'mapData' },
      { title: '首页', routeName: 'mapData', bg: '2' },
    ],
    unitDevice: [],
    regionalData: regionalData,
    introduce: {},
    weatherInfo: null,
    gisData: {
      boundary: '/data/boundary.kml'
    },
    userAuth: 0,
  }),
  mutations: {

    /**
     * 设置天气
     */
    setWeatherInfo(state, params) {
      state.weatherInfo = params
    },

    /**
     * 设置GIS数据
     */
    setGisData(state, params) {
      state.gisData = params
    },

    /**
     * 设置单位信息
     * @param state
     * @param params
     */
    setUserInfo(state, params) {
      state.prolandID = params.prolandID
      state.nickName = params.nickName
      state.session = params.session
      state.navList = params.navList
      state.userAuth = params.userAuth
      // state.userAuth = 1
      state.platformName = { cn: params.platformName, en: params.platformEnName }
    },

    /**
     * 设置单位所有设备
     * @param state
     * @param params
     */
    setUnitDevice(state, params) {
      state.unitDevice = params
    },

    /**
     * 设置单位介绍及图片
     * @param state
     * @param params
     */
    setIntroduce(state, params) {
      state.introduce = params
    },

    /**
     * 清空用户信息
     * @param state
     */
    clearUserInfo(state) {
      state.prolandID = ''
      state.session = ''
      state.nickName = ''
      state.platformName = { en: 'Ecological monitoring and perception platform of Ruyuan Grand Canyon', cn: config.title }
      state.navList = []
      state.unitDevice = []
      state.introduce = {}
      state.userAuth = 0
      state.weatherInfo = null
      state.gisData = { boundary: '' }
    },
  },
  actions: {
    /**
     * 获取天气
     * @param state
     * @param commit
     * @returns {Promise<boolean>}
     */
    async getWeather({state, commit}) {
      const res = await getWeather({ city: '清远' })
      if (res.messageType === 0) {
        let img = ''
        if (res.weather) {
          switch (res.weather.weather) {
            case '暴雪':
            case '大雪-暴雪':
              img = 'blizzard,bigSnow-blizzard'
              break
            case '暴雨':
              img = 'heavyRains'
              break
            case '大暴雨':
              img = 'torrentialRain'
              break
            case '大风':
              img = 'bigWind'
              break
            case '大雪':
            case '中雪-大雪':
              img = 'blizzard,bigSnow-blizzard'
              break
            case '大雨':
              img = 'bigRain'
              break
            case '风暴':
              img = 'storm'
              break
            case '浮尘':
              img = 'flyAsh'
              break
            case '疾风':
              img = 'hightWind'
              break
            case '飓风':
              img = 'hurricane'
              break
            case '狂暴风':
              img = 'violentWind'
              break
            case '雷阵雨':
              img = 'thunderShower'
              break
            case '雷阵雨并伴有冰雹':
              img = 'ThunderShowersWereAccompaniedByHail'
              break
            case '冷':
              img = 'cold'
              break
            case '烈风':
              img = 'heavyWind'
              break
            case '龙卷风':
              img = 'tornado'
              break
            case '霾':
              img = 'haze'
              break
            case '毛毛细雨/细雨':
              img = 'drizzle'
              break
            case '浓雾':
            case '强浓雾':
            case '特强浓雾':
              img = 'nongfog,strongFog,extremeFog'
              break
            case '强风/劲风':
              img = 'strongWind,strongBreeze'
              break
            case '强雷阵雨':
            case '极端降雨':
              img = 'strongThunderShower,extremeShower'
              break
            case '强沙尘暴':
              img = 'strongSandStorm'
              break
            case '强阵雨':
              img = 'StrongRainShower'
              break
            case '轻雾':
            case '大雾':
              img = 'lightFog,bigFog'
              break
            case '清风':
              img = 'refreshingBreeze'
              break
            case '晴':
              img = 'sun'
              break
            case '热':
              img = 'hot'
              break
            case '沙尘暴':
              img = 'dustStorms'
              break
            case '少云':
            case '晴间多云':
            case '多云':
              img = 'smallCloud,sun&moreCloud,moreCloud'
              break
            case '特大暴雨':
              img = 'rainstorm'
              break
            case '微风':
            case '和风':
              img = 'gentelBreeze,solfBreeze'
              break
            case '雾':
              img = 'fog'
              break
            case '小雪':
              img = 'smallSnow'
              break
            case '小雨':
              img = 'smallRain'
              break
            case '雪':
              img = 'snow'
              break
            case '扬沙':
              img = 'blowingSand'
              break
            case '阴':
              img = 'cloudy'
              break
            case '有风':
            case '平静':
              img = 'windiness-calm'
              break
            case '雨':
              img = 'rain'
              break
            case '雨雪天气':
            case '雨夹雪':
            case '阵雨夹雪':
            case '冻雨':
              img = 'rain&snow,sleet,arraySleet,freezingRain'
              break
            case '阵雪':
              img = 'snowShower'
              break
            case '阵雨':
              img = 'shower'
              break
            case '中雪':
            case '小雪-中雪':
              img = 'middleSnow,smallSnow-middleSnow'
              break
            case '中雨':
              img = 'middleRain'
              break
            default:
              img = 'smallCloud,sun&moreCloud,moreCloud'
          }
          res.weather.img = img
          commit('setWeatherInfo', res.weather)
        }
        return true;
      } else {
        console.error(res.message)
        return false;
      }
    }
  }
}
