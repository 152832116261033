/**
 * 储存缓存信息
 * @param name 储存名称
 * @param value 储存值
 * @param isSession 是否存储于临时数据
 */
export const setStorageData = (name, value, isSession) => {
  let val = value;
  if (typeof value === 'object') {
    val = JSON.stringify(value);
  }
  if (isSession) {
    sessionStorage.setItem(name, val);
  } else {
    localStorage.setItem(name, val);
  }
};

/**
 * 获取储存后的缓存信息
 * @param name 储存名称
 * @param isSession 是否存储于临时数据
 * @returns {string|undefined|any}
 */
export const getStorageData = (name, isSession) => {
  let value;
  if (isSession === true) {
    value = sessionStorage.getItem(name);
  } else {
    value = localStorage.getItem(name);
  }
  try {
    if (value) {
      return JSON.parse(value);
    }
    return undefined;
  } catch (e) {
    return value;
  }
};

/**
 * Promise 进程睡眠
 * @param ms 睡眠时长
 * @returns {Promise<unknown>}
 */
export const processSleep = (ms) => new Promise(((resolve) => setTimeout(resolve, ms)));

/**
 * 递归获取子元素
 * @param arr 查找数组
 * @param indexArr 下标数组
 * @param index 下标数组下标
 * @param childrenKey 子数组KEY
 * @returns {null|*}
 */
export const getChildElement = (arr, indexArr, index = 0, childrenKey = 'children') => {
  const child = arr[indexArr[index]]
  if (index === indexArr.length - 1) return child
  if (!child[childrenKey]) return null
  return getChildElement(child[childrenKey], indexArr, index + 1, childrenKey)
}

/**
 * 全局音频播放
 * @param url
 */
export const audioGlobalPlay = (url) => {
  if (!window.globalBackgroundAudio) {
    window.globalBackgroundAudio = document.createElement('AUDIO')
  }
  window.globalBackgroundAudio.setAttribute('src', url)
  window.globalBackgroundAudio.load()
  window.globalBackgroundAudio.play()
}
/**
 * 全局音频停止
 */
export const audioGlobalPause = () => {
  if (window.globalBackgroundAudio) window.globalBackgroundAudio.pause()
}


/**
 * 随机生成颜色
 * @param colors 排除重复颜色数组
 * @returns {string|*}
 */
export const randomColor = (colors) => {
  const color = `#${Math.random().toString(16).substr(2, 6)}`.toUpperCase()
  if (colors && colors.length) {
    for (let i = 0; i < colors.length; i++) {
      if (colors[i].toUpperCase() === color) {
        return randomColor(colors)
      }
    }
  }
  return color
}

/**
 * 秒转秒表格式 mm:ss.ss
 * @param s
 * @returns {string}
 */
export const timeS_Ms = (s) => {
  let m = 0, s2 = s
  if (s >= 60) {
    m = Math.floor(s / 60)
    s2 = s % 60
  }
  if (m < 10) m = `0${m}`
  if (s2 < 10) return`${m}:0${s2.toFixed(3)}`
  return `${m}:${s2.toFixed(3)}`
}

/**
 * 指定范围随机数
 * @param min
 * @param max
 * @returns {number}
 */
export const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值
}


/**
 * buffer转Base64
 * @param buffer
 * @returns {string}
 */
export const transformArrayBufferToBase64 = (buffer) => {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  for (let len = bytes.byteLength, i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
